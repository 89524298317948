import { cn } from "@ksoc-private/ui-core";
import { useNavigation } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";

export function GlobalLoadingBar() {
  const navigation = useNavigation();
  const active = navigation.state !== "idle";

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(
      ref.current.getAnimations().map(({ finished }) => finished),
    ).then(() => !active && setAnimationComplete(true));
  }, [active]);

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? "Loading" : undefined}
      className="tw-fixed tw-inset-x-0 tw-top-0 tw-left-0 tw-z-50 tw-h-1"
    >
      <div
        ref={ref}
        className={cn(
          "tw-h-full tw-bg-gradient-to-r tw-from-pink-500 tw-rounded-r-lg tw-to-indigo-500 tw-transition-all tw-duration-400",
          navigation.state === "idle" &&
            animationComplete &&
            "tw-w-0 tw-opacity-0 tw-transition-none",
          navigation.state === "submitting" && "tw-w-4/12",
          navigation.state === "loading" && "tw-w-10/12",
          navigation.state === "idle" && !animationComplete && "tw-w-full",
        )}
      />
    </div>
  );
}
