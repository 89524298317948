import { TooltipProvider, cn } from "@ksoc-private/ui-core";
import type { LinksFunction, MetaFunction } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import type { ShouldRevalidateFunction } from "@remix-run/react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { redirect } from "@remix-run/server-runtime";
import { withSentry } from "@sentry/remix";
import { NuqsAdapter } from "nuqs/adapters/remix";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalLoadingBar } from "~/components/TopLoadingBar";
import { useAnalyticsSetUser } from "~/hooks/useAnalytics";
import { RouteKeyContextProvider } from "~/routes/(tenants)/$tenantId/_components/route-key-context/RouteKeyContext";
import rootStyles from "~/styles/scss/root.scss?url";
import { ErrorMessage } from "./components/ErrorMessage";
import { inferLocaleFromRequest } from "./i18next.server";
import { createKratosClient, getKratosSession } from "./services/kratos.server";
import { getSession } from "./services/session.server";
import { userPermissions } from "./services/userPermissions.server";
import tailwindStyles from "./styles/tw/tailwind.css?url";
import { redirectToLogin } from "./util/redirectToLogin.server";
import type { LoaderArgs } from "./util/types";

export const meta: MetaFunction = () => [{ title: "RAD Security" }];

export const shouldRevalidate: ShouldRevalidateFunction = ({
  defaultShouldRevalidate,
  nextParams,
  currentParams,
  formMethod,
}) => {
  //  On a form submission lets revalidate
  if (formMethod) {
    return defaultShouldRevalidate;
  }
  if (currentParams.tenantId !== nextParams.tenantId) {
    return defaultShouldRevalidate;
  }
  if (currentParams.accountId !== nextParams.accountId) {
    return defaultShouldRevalidate;
  }
  return false;
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://rsms.me/" },
  { rel: "preconnect", href: "https://rsms.me/", crossOrigin: "anonymous" },
  { rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
  { rel: "stylesheet", href: tailwindStyles },
  { rel: "stylesheet", href: rootStyles },
  { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" },
  { rel: "icon", type: "image/png", href: "/favicon.png" },
];

export const handle = { i18n: ["common", "org"] };

type TenantRole = "read" | "modify";

export async function loader({ request, context }: LoaderArgs) {
  const locale = inferLocaleFromRequest(request);
  const requestUrl = new URL(request.url);
  // i.e. "platform-engineering-sbx" => "sbx"
  const envName = context.env.DATADOG_ENV?.split("-").at(-1) ?? "dev";

  const baseResponse = {
    locale,
    identity: null,
    orgs: [],
    canReadRootAccounts: false,
    tenantRoles: [] as TenantRole[],
    envName,
    cspNonce: crypto.randomUUID(),
    env: {
      version: import.meta.env.VITE_GIT_COMMIT_SHA ?? "unknown",
      datadogEnv: context.env.DATADOG_ENV,
      cfTurnstileSiteKey: context.env.CF_TURNSTILE_SITE_KEY,
    },
  };

  const kratos = createKratosClient(context);
  const auth = await getKratosSession(request, kratos);

  // anonPaths includes paths that can be accessed by users who are not authenticated
  const anonPaths = [
    "/",
    "/sign-in",
    "/sign-in/oidc/callback/google",
    "/sign-in/oidc/callback/github",
    "/sign-in/oidc/callback/okta",
    "/sign-in/oidc/callback/ping",
    "/invitations/accept",
    "/invitations/invalid",
    "/invitations/onboard",
    "/okta-sign-in",
    "/ping-sign-in",
    "/enterprise-sso",
    "/recovery",
    "/recovery/self-service/recovery",
    "/settings",
    "/errors",
    "/onboarding/create-tenant",
    "/onboarding/create-user",
    "/onboarding/check-your-email",
  ];

  // noAccountPaths includes paths that can be accessed by users who are not yet a member of an account
  const noAccountPaths = [
    "/invitations/accept",
    "/no-accounts",
    "/settings",
    "/onboarding",
  ];

  if (!auth?.active && !anonPaths.includes(requestUrl.pathname)) {
    return redirectToLogin(request, context.env.SESSION_SIGNING_KEY);
  }

  if (auth?.active && !noAccountPaths.includes(requestUrl.pathname)) {
    const [session, permissions] = await Promise.all([
      getSession(request, context.env.SESSION_SIGNING_KEY),
      userPermissions(context.api),
    ]);
    let rootAccountId: string | undefined;
    const { accounts: allAccounts } = await context.api.listAccounts();
    if (permissions.canReadRootAccounts) {
      rootAccountId = session.get("accountId");
    } else {
      rootAccountId = allAccounts.find((a) => !a.parent_id)?.id;
      if (!rootAccountId) {
        return redirect("/no-accounts");
      }
    }

    const tenantRoles = getRoles(rootAccountId, permissions);

    const { accounts } = await context.api.listAccounts({
      parentId: rootAccountId,
    });

    const rootAccount =
      allAccounts.find((a) => a.id === rootAccountId) ??
      accounts.find((a) => a.id === rootAccountId);
    const orgs = accounts.filter((a) => a.id !== rootAccountId);
    const response = {
      ...baseResponse,
      identity: auth.identity,
      orgs,
      rootAccount,
      canReadRootAccounts: permissions.canReadRootAccounts,
      tenantRoles,
    };
    session.set("authExpiry", auth.expires_at);
    if (session.get("accountId") !== rootAccountId) {
      session.set("accountId", rootAccountId);
      return session.commitWithResponse(json(response));
    }
    return session.commitWithResponse(json(response));
  }

  return json(baseResponse);
}

function getRoles(
  rootAccountId: string | undefined,
  permissions: Awaited<ReturnType<typeof userPermissions>>,
): TenantRole[] {
  if (permissions.canReadRootAccounts) {
    return ["read", "modify"];
  }

  const availablePermissons: TenantRole[] = [];
  if (rootAccountId) {
    if (permissions.canModifyAccount(rootAccountId)) {
      availablePermissons.push("modify");
    }
    if (permissions.canReadAccount(rootAccountId)) {
      availablePermissons.push("read");
    }
  }

  return availablePermissons;
}

function App() {
  const { locale, canReadRootAccounts, identity, cspNonce } =
    useLoaderData<typeof loader>();
  const nonce = typeof document !== "undefined" ? "" : cspNonce;
  const { i18n } = useTranslation();
  useAnalyticsSetUser(
    identity ? { id: identity.id, email: identity.traits.email } : undefined,
  );
  useEffect(() => void i18n.changeLanguage(locale), [locale, i18n]);
  return (
    <html lang={locale} dir={i18n.dir()} className="tw-h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <EnvScript />
      </head>
      <body
        className={cn(
          canReadRootAccounts ? " hasAdminBar " : "",
          "nav-bar-v2",
          "tw-h-full",
        )}
      >
        <GlobalLoadingBar />
        <RouteKeyContextProvider>
          <TooltipProvider>
            <NuqsAdapter>
              <Outlet />
            </NuqsAdapter>
          </TooltipProvider>
        </RouteKeyContextProvider>
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <script
          src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"
          async
          defer
          nonce={nonce}
        ></script>
        <VersionInfo />
      </body>
    </html>
  );
}

export default withSentry(App);

export const ErrorBoundary = () => {
  return (
    <html>
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorMessage />
        <Scripts />
      </body>
    </html>
  );
};

function VersionInfo() {
  const { env } = useLoaderData<typeof loader>();
  return (
    <aside className="tw-fixed tw-bottom-0 tw-right-0 tw-text-[8px] tw-p-2">
      <span>
        app version: <code>{env.version}</code>
      </span>
    </aside>
  );
}

function EnvScript() {
  const { env, cspNonce } = useLoaderData<typeof loader>();
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `window.__envContext = ${JSON.stringify(env)};`,
      }}
      nonce={typeof document !== "undefined" ? "" : cspNonce}
    />
  );
}
